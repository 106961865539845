import React, { FC } from "react";
import { Box, Text } from "theme-ui";

const PaymentComplete: FC = () => {
  return (
    <Box variant="layout.payment">
      <Text>Redirecting...</Text>
    </Box>
  );
};

export default PaymentComplete;
